<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> Registro de Pagos
        <div class="card-header-actions">
            Monto Pagado: {{total | currency}}
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="payments"
        :fields="fields"
        :dark="dark"
      >
        <template #name="{item}"><td class="text-left">{{item.name}}<strong v-if="item.enrollment_payment && item.enrollment_payment.observations"><br /><br />Observaciones: </strong>{{item.enrollment_payment.observations}}</td></template>
        <template #amount="{item}"><td class="w200 text-center" style="font-size: 14px;">
          <span v-if="item.amount <= 100 || item.amount == 88888 || item.amount == 99999">N/D<br /></span>
          <span v-if="item.amount > 100 && item.amount != 88888 && item.amount != 99999">
            <span v-if="!item.enrollment_payment">
              <span v-if="item.amount == 88888 || item.amount == 99999">N/D<br /></span>
              <span v-if="item.amount != 88888 && item.amount != 99999">
                {{travel.type != 'national' ? 'USD' : ''}} {{item.amount | currency}} {{travel.type != 'national' ? '' : 'Pesos'}}
                <br />
              </span>
              <span v-if="!item.enrollment_payment">
                <br /><CButton color="danger" size="sm" @click="paymentModal(item, student)" v-c-tooltip="{content: 'Registrar Pago', placement: 'top'}">PAGAR</CButton>
              </span>
            </span>
            <span v-if="item.enrollment_payment">
              {{item.enrollment_payment.date | date}}<br />
              MXN {{item.enrollment_payment.amount | currency}} 
              <br />
              <span>
                <br />
                <CButton color="success" size="sm" @click="updatePaymentModal(item, student)" v-c-tooltip="{content: 'Editar Pago', placement: 'top'}">PAGADO</CButton>
                <CButton v-if="item.enrollment_payment.filename != 'N/A'" color="info" style="height: 27px; padding:0px 6px 0 7px; margin-left: 5px;" size="sm" @click="downloadReceipt(item, student)" v-c-tooltip="{content: 'Comprobante de Pago', placement: 'top'}"><CIcon name="cil-file"/></CButton>
                <CButton color="danger" style="height: 27px; padding:0px 6px 0 7px; margin-left: 5px;" size="sm" @click="deletePaymentModal(item, student)" v-c-tooltip="{content: 'Eliminar Pago', placement: 'top'}"><CIcon name="cil-trash"/></CButton>
              </span>
            </span>
          </span>
        </td></template>
        
        <template #actions="{item}">
          <td class="table_actions w150">
            <CButton color="success" size="sm" @click="activeModal(item)" v-c-tooltip="{content: 'Agregar Pago', placement: 'top'}">Nuevo Pago</CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <PaymentModal ref="paymentModal" @store="storeProcess" @update="updateProcess" @validate="validateProcess" @destroy="destroyProcess"></PaymentModal>
  </CCard>
</template>

<script>
import PaymentModal from './PaymentModal.vue'
import ws from '../../../services/travels';
import payments from '../../../services/payments';
import store from '../../../store'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

export default {
  name: 'StudentPaymentsTable',
  components: { PaymentModal },
  props: {
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Identificador del Pago'},
          {key: 'amount', label: 'Monto del Pago'},
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    id: 0,
    travel: {},
    student: {},
  },
  data() {
    return {
      toasts: [],
      students: [],
      payments: [],
      total: 0
    }
  },
  mounted: async function() {
    let _this = this;

    setTimeout(async function(){ 
        let response = await ws.studentPayments(_this.id);
        
        _this.total = 0;

        if(response.type == "success"){
          _this.payments = response.data;

          let i = 0;
          _this.payments.forEach(payment => {
              let number = Number.parseFloat(payment.enrollment_payment.amount > 0 ? payment.enrollment_payment.amount * 1 : 0 * 1).toFixed(2) * 1;
              _this.total += number;
          });
        }
      }, 100);
  },
  methods: {
    paymentModal (payment, student) {
      this.$refs.paymentModal.storeModal(student, payment, this.travel.exchange_rate);
    },
    updatePaymentModal (payment, student) {
      this.$refs.paymentModal.updateModal(student, payment, this.travel.exchange_rate);
    },
    validatePaymentModal (payment, student) {
      this.$refs.paymentModal.validateModal(student, payment, this.travel.exchange_rate);
    },
    downloadReceipt (payment, student) {
      var link = document.createElement('a');
      link.href = payment.enrollment_payment.filename;
      link.download = payment.enrollment_payment.filename.substr(payment.enrollment_payment.filename.lastIndexOf('/') + 1);
      link.click();
    },
    deletePaymentModal (payment, student) {
      this.$refs.paymentModal.deleteModal(student, payment, this.travel.exchange_rate);
    },
    async storeProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.store(data);
      
      if(response.type == "success"){
        payment.enrollment_payment = response.data;
        
        let _this = this;

        setTimeout(async function(){ 
          let response2 = await ws.studentPayments(_this.id);
          
          _this.total = 0;

          if(response2.type == "success"){
            _this.payments = response2.data;

            let i = 0;
            _this.payments.forEach(payment => {
              let number = Number.parseFloat(payment.enrollment_payment.amount > 0 ? payment.enrollment_payment.amount * 1 : 0 * 1).toFixed(2) * 1;
              _this.total += number;
            });
          }
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.update(data);

      if(response.type == "success"){
        payment.enrollment_payment = response.data;

        let _this = this;

        setTimeout(async function(){ 
          let response2 = await ws.studentPayments(_this.id);
          
          _this.total = 0;

          if(response2.type == "success"){
            _this.payments = response2.data;

            let i = 0;
            _this.payments.forEach(payment => {
                let number = Number.parseFloat(payment.enrollment_payment.amount > 0 ? payment.enrollment_payment.amount * 1 : 0 * 1).toFixed(2) * 1;
                _this.total += number;
            });
          }
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    async validateProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.validate(data);

      if(response.type == "success"){
        payment.enrollment_payment = response.data;

        let _this = this;

        setTimeout(async function(){ 
          let response2 = await ws.studentPayments(_this.id);
          
          _this.total = 0;

          if(response2.type == "success"){
            _this.payments = response2.data;

            let i = 0;
            _this.payments.forEach(payment => {
                let number = Number.parseFloat(payment.enrollment_payment.amount > 0 ? payment.enrollment_payment.amount * 1 : 0 * 1).toFixed(2) * 1;
                _this.total += number;
            });
          }
        }, 100);
      }

      this.showToast(response.type, response.message);
    },

    async destroyProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.delete(data);

      if(response.type == "success"){
        payment.enrollment_payment = false;

        let _this = this;

        setTimeout(async function(){ 
          let response2 = await ws.studentPayments(_this.id);
          
          _this.total = 0;

          if(response2.type == "success"){
            _this.payments = response2.data;

            let i = 0;
            _this.payments.forEach(payment => {
              console.log(payment.enrollment_payment.amount);
                let number = Number.parseFloat(payment.enrollment_payment.amount > 0 ? payment.enrollment_payment.amount * 1 : 0 * 1).toFixed(2) * 1;
                _this.total += number;
            });
          }
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
