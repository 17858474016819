<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> Información del Alumno
        <div class="card-header-actions">
          <CRow>
            <CButton v-if="student && student.enrollment && student.enrollment.contract_filename != ''" color="info" size="sm" @click="downloadContract(student.enrollment.contract_filename)" v-c-tooltip="{content: 'Ver Contrato', placement: 'top'}">Contrato</CButton><br />
            <CButton v-if="student && student.enrollment && student.enrollment.responsive_filename != ''" color="info" size="sm" @click="downloadResponsive(student.enrollment.responsive_filename)" v-c-tooltip="{content: 'Ver Responsiva', placement: 'top'}">Responsiva</CButton><br />            
          </CRow>          
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="questions"
        :fields="fields"
        :dark="dark"
      >
        <template #answer="{item}">
          <td :class="item.type == 'file' ? 'text-center' :'text-left'">
            <span v-if="item.type == 'date'">{{item.answer.answer | date}}</span>
            <span v-if="item.type == 'file'">
              <CButton color="info" size="sm" @click="uploadModal(student.id, item, {label: item.question, key: item.id})" style="margin-right: 10px;">Cargar Archivo</CButton>
              <CButton v-if="item.answer.answer !== undefined && item.answer.answer != ''" color="success" size="sm" @click="downloadFile(item.answer.answer)" v-c-tooltip="{content: 'Descargar', placement: 'top'}">Descargar Archivo</CButton>
            </span>
            <span v-if="item.type == 'text'">{{item.answer.answer}}</span>
            <span v-if="item.type == 'options'">{{item.answer.answer}}</span>
            <span v-if="item.type == 'number'">{{item.answer.answer}}</span>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <FileUpload ref="uploadModal" @upload="upload"></FileUpload>
  </CCard>
</template>

<script>
import ws from '../../../services/travels';
import sutdents from '../../../services/students';
import store from '../../../store'
import FileUpload from './FileUpload.vue'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

export default {
  name: 'StudentQuestionsTable',
  components: { FileUpload },
  props: {
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'question', label: 'Pregunta'},
          {key: 'answer', label: 'Respuesta'},
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    id: 0,
    travel: {},
    student: {},
  },
  data() {
    return {
      toasts: [],
      students: [],
      questions: [],
    }
  },
  mounted: async function() {
    let _this = this;    
    setTimeout(async function(){ 
        let response = await ws.studentQuestions(_this.id);
        
        _this.total = 0;

        if(response.type == "success"){
          _this.questions = response.data;
        }
      }, 100);
  },
  methods: {
    paymentModal (payment, student) {
      this.$refs.paymentModal.storeModal(student, payment, this.travel.exchange_rate);
    },
    updatePaymentModal (payment, student) {
      this.$refs.paymentModal.updateModal(student, payment, this.travel.exchange_rate);
    },
    uploadModal (student_id, item, field) {
      this.$refs.uploadModal.show2("Cargar Archivo", student_id, item, field);
    },
    upload (item, field, data) {
      item[field.key] = data;
    },
    downloadFile (url) {
      var link = document.createElement('a');
      link.href = url;
      link.click();
    },
    deletePaymentModal (payment, student) {
      this.$refs.paymentModal.deleteModal(student, payment, this.travel.exchange_rate);
    },
    async storeProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await questions.store(data);
      
      if(response.type == "success"){
        payment.enrollment_payment = response.data;
        
        let _this = this;

        setTimeout(async function(){ 
          let response2 = await ws.studentQuestions(_this.id);
          
          _this.total = 0;

          if(response2.type == "success"){
            _this.payments = response2.data;

            let i = 0;
            _this.payments.forEach(payment => {
              let number = Number.parseFloat(payment.enrollment_payment.amount > 0 ? payment.enrollment_payment.amount * 1 : 0 * 1).toFixed(2) * 1;
              _this.total += number;
            });
          }
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await questions.update(data);

      if(response.type == "success"){
        payment.enrollment_payment = response.data;

        let _this = this;

        setTimeout(async function(){ 
          let response2 = await ws.studentQuestions(_this.id);

          if(response2.type == "success"){
            _this.questions = response2.data;
          }
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    async destroyProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await questions.delete(data);

      if(response.type == "success"){
        payment.answer = false;

        let _this = this;

        setTimeout(async function(){ 
          let response2 = await ws.studentQuestions(_this.id);

          if(response2.type == "success"){
            _this.questions = response2.data;
          }
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    async downloadContract (url) {
      console.log(url);
      window.open(url, '_blank');
    },
    async downloadResponsive (url) {
      window.open(url, '_blank');
    },
  }
}
</script>
