<template>
  <div>
    <CRow>
      <CCol sm="12">
        <span style="float: right; text-align: right;">
          {{student.customer_name}}<br/>{{student.customer_phone}}<br /><br />
          <CButton color="info" size="sm" @click="showTravel(student.travel_id)">Volver al Viaje</CButton>
        </span>
        <h1>{{student.name}}</h1>
        <br />
        <CNav variant="tabs" role="tablist">
          <li class="nav-item" :class="tab === 'information' ? 'tab_active' : ''"><a href="#" target="_self" class="nav-link" @click="changeTab('information')"><CLink href="javascript:void(0);" :active="tab === 'information'"> Información </CLink></a></li>
          <li class="nav-item" :class="tab === 'payments' ? 'tab_active' : ''"><a href="#" target="_self" class="nav-link" @click="changeTab('payments')"><CLink href="javascript:void(0);" :active="tab === 'payments'"> Pagos </CLink></a></li>
        </CNav>
        <CTabs class="remove">
          <CTab role="tabpanel" aria-labelledby="information-tab" v-if="tab === 'information'">
            <StudentQuestionsTable
              hover
              striped
              border
              small
              fixed
              caption="Pagos del Alumno"
              :id="id"
              type="student"
              :student="student"
              :travel="travel"
              ref="studentQuestionTable"
            />
          </CTab>
          <CTab role="tabpanel" aria-labelledby="payments-tab" v-if="tab === 'payments'">
            <StudentPaymentsTable
                hover
                striped
                border
                small
                fixed
                caption="Pagos del Alumno"
                :id="id"
                type="student"
                :student="student"
                :travel="travel"
              />
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import StudentQuestionsTable from '../../components/travels/students/StudentQuestionsTable.vue'
import StudentPaymentsTable from '../../components/travels/payments/StudentPaymentsTable.vue'
import travels from '../../services/travels';
import students from '../../services/students';
import store from '../../store'

export default {
  name: 'Student',
  components: { StudentPaymentsTable, StudentQuestionsTable },
  data: function () {
		return {
            tab: 'information',
            travel: false,
            student: false,
            payments: [],
            id: 0
        }
  },
  mounted: async function() {
    this.loading();

    this.id = await this.$route.params.id;

    let response = await students.showById(this.id);

    if(response.type == "success"){
      this.student = response.data;
    }

    let response2 = await travels.showById(this.student.travel_id);

    if(response2.type == "success"){
      this.travel = response2.data;
    }

    this.changeTab("information")

    this.loaded();
  },
  methods: {
    async changeTab(tab){
      this.loading();
      this.tab = tab;

      this.loaded();
    },
    showTravel (travel_id) {
      this.$router.push({path: '/travels/'+travel_id+'/show'})
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
<style>
.remove .nav.nav-tabs{
  display: none;
}
</style>